class IconHeart extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
<svg width="34" height="29" viewBox="0 0 34 29" fill="none" xmlns="http://www.w3.org/2000/svg" style="width: 100%;height: 100%">
<path d="M17.0007 29C16.8391 29 16.6779 28.9619 16.5318 28.8846C9.23532 25.023 1.3505 20.2524 0.157115 12.4828C-0.389531 8.92289 0.500777 5.65843 2.66383 3.29069C4.5747 1.19936 7.3085 0 10.1636 0C12.7165 0 15.1085 0.952944 17.0011 2.70431C18.8932 0.953016 21.2852 0 23.8374 0C26.6925 0 29.4257 1.19958 31.3366 3.29098C33.4997 5.65857 34.3897 8.92304 33.8427 12.4823C32.6999 19.9189 24.7939 24.5735 17.819 28.6797L17.5087 28.8623C17.3537 28.954 17.1776 28.9997 17.0011 28.9997L17.0007 29ZM10.1635 1.8204C7.86662 1.8204 5.66313 2.79033 4.11738 4.48209C2.32486 6.44389 1.59402 9.19219 2.05871 12.2214C3.10132 19.0056 10.2224 23.4319 16.976 27.0339C23.8651 22.9773 30.935 18.7665 31.9407 12.2214C32.4062 9.19255 31.6754 6.44425 29.8825 4.48209C28.3371 2.7909 26.1335 1.8204 23.837 1.8204C21.5175 1.8204 19.35 2.81684 17.7345 4.62686C17.3697 5.03548 16.6317 5.03605 16.2669 4.62714C14.6517 2.81712 12.4839 1.82069 10.1638 1.82069L10.1635 1.8204Z" fill="currentColor"/>
</svg>
    `
  }
}
if(!window.customElements.get("icon-heart")) {
  window.customElements.define("icon-heart", IconHeart)
}