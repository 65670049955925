import "./index.scss";
import "./icons"
import React from 'react';
import ReactDOM from "react-dom/client";
import {injectors} from "./injectors";

injectors.forEach(i => {
  const elements = document.querySelectorAll(i.selector)
  elements.forEach(element => {
    if(element) {
      let props = {}
      if(i.getprops) {
        props = i.getprops(element)
      }
      const c = React.createElement(i.component,props)
      ReactDOM.createRoot(element).render(c)
    }
  })
})

