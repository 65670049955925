class IconCalendarSm extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" style="width: 1em;height: 1em">
<path d="M10.1799 2.44576V0.894531M10.1799 2.44576V3.99699M10.1799 2.44576H6.68967M0.872559 7.09945V14.08C0.872559 14.9367 1.56707 15.6312 2.42379 15.6312H13.2824C14.1391 15.6312 14.8336 14.9367 14.8336 14.08V7.09945H0.872559Z" stroke="#7E7D7D" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M0.872559 7.099V3.99654C0.872559 3.13982 1.56707 2.44531 2.42379 2.44531H3.97502" stroke="#7E7D7D" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.97559 0.894531V3.99699" stroke="#7E7D7D" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.8331 7.099V3.99654C14.8331 3.13982 14.1386 2.44531 13.2819 2.44531H12.894" stroke="#7E7D7D" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
    `
  }
}

if (!window.customElements.get("icon-calendar-sm")) {
  window.customElements.define("icon-calendar-sm", IconCalendarSm)
}