import "./FlatFilter.scss"
import React, {useState} from "react";
import {FlatFilterSelector} from "./FlatFilterSelector";

export function FlatFilter() {

  const [a1,setA1] = useState(true)
  const [a2,setA2] = useState(true)
  const [a3,setA3] = useState(false)
  const [a4,setA4] = useState(false)

  return <>
    <div className="aptbuttons">
      <button className={a1?"active":""} onClick={()=>setA1(!a1)}>1</button>
      <button className={a2?"active":""} onClick={()=>setA2(!a2)}>2</button>
      <button className={a3?"active":""} onClick={()=>setA3(!a3)}>3</button>
      <button className={"disabled " + (a4?"active":"")}>4+</button>
    </div>
    <div component='FlatFilterSelector'>
      <FlatFilterSelector/>
    </div>
  </>
}