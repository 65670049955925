class IconCurrencySq extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.872559 15.0884V1.334C0.872559 1.0627 1.09249 0.842773 1.36379 0.842773H15.1182C15.3895 0.842773 15.6094 1.0627 15.6094 1.334V15.0884C15.6094 15.3597 15.3895 15.5796 15.1182 15.5796H1.36379C1.09249 15.5796 0.872559 15.3597 0.872559 15.0884Z" stroke="#7E7D7D"/>
<path d="M10.6969 5.34579C10.1361 4.78498 9.14852 4.39489 8.24081 4.37047M8.24081 4.37047C7.16084 4.34141 6.19402 4.82998 6.19402 6.16451C6.19402 8.62064 10.6969 7.39257 10.6969 9.84871C10.6969 11.2495 9.49852 11.8515 8.24081 11.8063M8.24081 4.37047V2.88965M5.78467 10.6674C6.31231 11.3709 7.29339 11.7722 8.24081 11.8063M8.24081 11.8063V13.5329" stroke="#7E7D7D" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
    `
  }
}

if (!window.customElements.get("icon-currency-sq")) {
  window.customElements.define("icon-currency-sq", IconCurrencySq)
}