import "./FlatMapCard.scss"
import React from "react";

export function FlatMapCard() {

  const goToFloor = () => {
    window.location='floor.html'
  }

  return <>
    <section>
      <span>від 88.4 м2</span>
      <label>від 3.96 млн грн</label>
    </section>
    <div>
      <a href="#">1K</a>
      <a href="#">2K</a>
      <a href="#" className="active">3K</a>
    </div>
    <a href="#" onClick={()=>goToFloor()}>
      <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 10L6 5.5L1 1" stroke="#111111" strokeOpacity="0.5" strokeWidth="2"/>
      </svg>
    </a>
  </>
}