class IconAptSection extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
<svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg" style="width: 1em;height: 1em">
  <path d="M5.23535 8.43695L5.2462 8.42676" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M9.49316 8.43695L9.50401 8.42676" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M5.23535 12.0375L5.2462 12.0273" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M9.49316 12.0375L9.50401 12.0273" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M0.978027 16.5647V3.23605C0.978027 2.93762 1.26394 2.6957 1.61662 2.6957H7.36399V1.43488C7.36399 1.13646 7.64987 0.894531 8.00259 0.894531H13.1114C13.4641 0.894531 13.75 1.13646 13.75 1.43488V16.5647C13.75 16.8632 13.4641 17.1051 13.1114 17.1051H1.61662C1.26394 17.1051 0.978027 16.8632 0.978027 16.5647Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
    `
  }
}

if (!window.customElements.get("icon-apt-section")) {
  window.customElements.define("icon-apt-section", IconAptSection)
}