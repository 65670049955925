class IconFloorPlan extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
<svg width="31" height="26" viewBox="0 0 31 26" fill="none" xmlns="http://www.w3.org/2000/svg" style="width: 100%;height: 100%">
<path d="M29.5 17V6.5H16.5V1.5H1V24.5H16.5V22M16.5 22H30.5M16.5 22V21" stroke="currentColor" stroke-width="2"/>
<path d="M16.5 12V23" stroke="currentColor" stroke-width="2"/>
</svg>
    `
  }
}
if(!window.customElements.get("icon-floor-plan")) {
  window.customElements.define("icon-floor-plan", IconFloorPlan)
}