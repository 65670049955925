class IconHouses extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
<svg width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg" style="width: 100%;height: 100%">
<path d="M6.99715 12.4056H14.0847M6.99715 19.6894H14.0847M6.99715 26.4531H14.0847M1 33.2167V6.6825L21.1722 2V33.2167H1Z" stroke="currentColor" stroke-width="2"/>
<path d="M26.6334 19.3512H30.2971M26.6334 25.2824H30.2971M34.8025 32.6963H21.7178V12.9258H34.8025V32.6963Z" stroke="currentColor" stroke-width="2"/>
</svg>
    `
  }
}
if(!window.customElements.get("icon-houses")) {
  window.customElements.define("icon-houses", IconHouses)
}