class IconHouse extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
<svg width="23" height="35" viewBox="0 0 23 35" fill="none" xmlns="http://www.w3.org/2000/svg" style="width: 100%;height: 100%">
<path d="M6.99715 12.4056H14.0847M6.99715 19.6894H14.0847M6.99715 26.4531H14.0847M1 33.2167V6.6825L21.1722 2V33.2167H1Z" stroke="currentColor" strokeWidth="2"/>
</svg>
    `
  }
}
if(!window.customElements.get("icon-house")) {
  window.customElements.define("icon-house", IconHouse)
}