import {TopRightNav} from "./cmp/TopRightNav";
import {Orientir} from "./cmp/Orientir";
import {FlatMapCard} from "./cmp/FlatMapCard";
import {YearSlider} from "./cmp/YearSlider";
import {FlatFilter} from "./cmp/FlatFilter";

export const injectors = [
  {
    selector:"div[component='FlatFilter']",
    component: FlatFilter,
    getprops:(e:Element)=>{
      return {}
    }
  },
  {
    selector:"div.FlatMapCard",
    component: FlatMapCard,
    getprops:(e:Element)=>{
      return {}
    }
  },
  {
    selector:"div.Orientir",
    component: Orientir,
    getprops:(e:Element)=>{
      return {}
    }
  },
  {
    selector:"div#top-right-nav",
    component: TopRightNav,
    getprops:(e:Element)=>{
      return {}
    }
  },
  {
    selector: "div[component='YearSlider']",
    component: YearSlider,
    getprops:(e:Element) => {
      return {}
    }
  }
]