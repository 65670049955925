import "./YearSlider.scss"
import React, {useState} from "react";
import type {SliderSingleProps} from 'antd';
import {Slider} from "antd";

export function YearSlider() {

  const y = (new Date).getFullYear()

  const marks:SliderSingleProps['marks'] = {}

  marks[y-2] = "..."
  marks[y-1] = "Завершені"
  marks[y] = y.toString()
  marks[y+1] = (y+1).toString()
  marks[y+2] = (y+2).toString()

  const [value, setValue] = useState(y)

  return <>
    <h4>Квартири {y-2}-{value} р. побудови</h4>
    <Slider marks={marks} max={2026} min={2022} value={value} onChange={setValue}/>
  </>
}