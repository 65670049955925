class IconMap extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
<svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg" style="width: 100%;height: 100%">
<path d="M11 8L1.5 1.5V22L11 29M11 8V29M11 8L20 1M11 29L20 22M20 1L29.5 8V29L20 22M20 1V22" stroke="currentColor" stroke-width="2" stroke-linejoin="round"/>
</svg>
    `
  }
}
if(!window.customElements.get("icon-map")) {
  window.customElements.define("icon-map", IconMap)
}