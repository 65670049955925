import "./TopRightNav.scss"
import React from 'react';
export function TopRightNav() {

  return <div className="TopRightNav">
    <a href="#">
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21.5905 10.6209C21.5893 7.8072 20.4479 5.10897 18.4168 3.11789C16.386 1.12681 13.6314 0.00559548 10.7573 0C-3.59348 0.502716 -3.57804 20.7237 10.7573 21.2265C13.6298 21.2244 16.3842 20.1065 18.4152 18.1181C20.4464 16.1293 21.5887 13.4331 21.5905 10.6209ZM11.5357 16.6707V17.8134H9.97919V16.7011H7.58212V15.1773H9.97919V11.3372C8.82541 11.2223 7.80564 10.5521 7.26019 9.54989C6.7144 8.5477 6.7144 7.34558 7.26019 6.34338C7.80568 5.34149 8.82549 4.67131 9.97919 4.55608V3.42867H11.5357V4.52568H13.9328V6.04947H11.5357V9.8742C12.6906 9.99205 13.7098 10.664 14.2553 11.6674C14.8005 12.6708 14.8005 13.8741 14.2553 14.8775C13.7098 15.8809 12.6906 16.5528 11.5357 16.6707ZM22.6958 32C20.2082 31.9947 17.8241 31.025 16.0651 29.303C14.3061 27.581 13.3155 25.2466 13.3101 22.8113H14.8666C15.1934 32.9293 30.1983 32.9293 30.525 22.8113C30.5262 20.7783 29.7014 18.8282 28.2329 17.3904C26.7642 15.9529 24.7723 15.1456 22.6954 15.1468V13.623C35.1013 14.0038 35.1017 31.6038 22.6958 32ZM20.2676 22.0499H23.5363V23.5737H20.2676C20.4489 24.4482 20.9343 25.2345 21.6409 25.7984C22.3475 26.3623 23.2319 26.6695 24.1433 26.6671H26.1044V28.1908H24.1433C22.8223 28.1879 21.5468 27.72 20.5493 26.8723C19.5519 26.0243 18.8993 24.8534 18.711 23.5736H17.248V22.0498H18.711C18.8986 20.7682 19.5503 19.5953 20.5475 18.7449C21.5446 17.8946 22.8209 17.4234 24.1433 17.4172H26.1044V18.941L24.1433 18.9413C23.2303 18.9422 22.3454 19.252 21.6391 19.8186C20.9328 20.3851 20.4483 21.1735 20.2676 22.0499ZM11.5358 15.1164V11.4441C12.1323 11.5419 12.6458 11.9117 12.9189 12.4401C13.1919 12.9682 13.1919 13.5923 12.9189 14.1208C12.6458 14.6493 12.1323 15.0186 11.5358 15.1164ZM9.97934 6.11071V9.78331C9.37442 9.69589 8.84974 9.32786 8.57006 8.79564C8.29068 8.26308 8.29068 7.63132 8.57006 7.09877C8.84975 6.56622 9.37442 6.19812 9.97934 6.11071Z"
          fill="currentColor"/>
      </svg>
    </a>
    <a href="#">
      <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23 12H-2.38419e-07" stroke="currentColor" strokeWidth="3"/>
        <path d="M23 22H7" stroke="currentColor" strokeWidth="3"/>
        <path d="M16 2H-2.98023e-07" stroke="currentColor" strokeWidth="3"/>
      </svg>
    </a>
  </div>

}