class IconCrane extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" style="width: 1em;height: 1em">
<path d="M16.3609 4.5838H16.3334L16.3186 4.60703L13.2561 9.43853L13.2484 9.45078V9.4653V15.2542H11.265V12.826V12.776H11.215H9.09031L4.6213 0.373675L4.60507 0.328623L4.55935 0.342898L2.00458 1.14076L1.95514 1.1562L1.97232 1.20507L2.09844 1.56372V7.03214C1.45864 7.26278 0.95 7.9006 0.95 8.66743C0.95 9.57557 1.69579 10.3575 2.57558 10.3575C3.45536 10.3575 4.20115 9.57557 4.20115 8.66743V8.61743H4.15115H3.29688H3.24688V8.66743C3.24688 9.10992 2.92453 9.45962 2.57155 9.45962C2.22243 9.45962 1.89622 9.10973 1.89622 8.66743C1.89622 8.22302 2.17992 7.87525 2.57155 7.87525H2.99868H3.04868V7.82525V4.30129L6.18446 13.322V15.2502H3.59507H3.54507V15.3002V20.1357V20.1857H3.59507H20.7451H20.7951V20.1357V4.6338V4.5838H20.7451H16.3609ZM10.3551 13.6738V15.2542H7.1347V13.6738H10.3551ZM4.0321 1.46151L8.07754 12.776H6.9511L3.15089 1.76738L4.0321 1.46151ZM19.8851 19.2435H4.49531V16.1964H19.8851V19.2435ZM14.1583 15.0447V9.6895L16.8596 5.48166H19.8448V15.0447H14.1583Z" fill="#7E7D7D" stroke="#7E7D7D" stroke-width="0.1"/>
<path d="M15.4258 8.66732V10.0938H19.2539V6.10449H17.1263L15.4258 8.66732ZM18.448 6.90235V9.25564H16.276V8.92119L17.5937 6.90638H18.448V6.90235Z" fill="#7E7D7D"/>
</svg>
    `
  }
}

if (!window.customElements.get("icon-crane")) {
  window.customElements.define("icon-crane", IconCrane)
}